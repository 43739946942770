/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export default {
  getFormData(form, action) {
    const formData = new FormData()

    if (Array.isArray(form.categories)) {
      formData.append('categoryId', form.categories[form.categories.length - 1].id)
    } else {
      formData.append('categoryId', form.categories.id)
    }

    formData.append('title', form.title)
    formData.append('description', form.description)
    formData.append('price1Day', form.price1Day)
    formData.append('price3Day', form.price3Day)
    formData.append('price7Day', form.price7Day)
    formData.append('returnGuarantees', form.returnGuarantees)
    formData.append('branches', form.branches)
    formData.append('shippable', Number.isInteger(form.shippable) ? form.shippable : 0)

    for (const imgIndex in form.images) {
      formData.append('images[]', form.images[imgIndex])
    }

    if (action === 'edit') {
      for (const image in form.newImages) {
        formData.append('newImages[]', form.newImages[image])
      }
    }

    return formData
  },

  checkCustomValidations(form, action) {
    const invalidCategories = form.categories.length === 0

    let missingImages = form.images.length === 0
    if (action !== 'add') {
      missingImages = form.images.length === 0 && form.newImages.length === 0
    }

    return {
      invalidCategories,
      missingImages,
    }
  },

  checkMissingUserInfo(user) {
    return user.branches == null || user.branches.length === 0
  },
}
