<script>
import store from '@/store'

export default {

  components: {
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['category', 'invalid'],
  data() {
    return {
      displayedCategories: [],
      breadCrumb: [{
        name: 'الكل',
        id: 0,
        parent_id: null,
      }],

      selectedCategory: null,
    }
  },

  computed: {
    flatCategories() {
      return store.getters['categories/flatCategories']
    },
  },

  watch: {
    category(newValue) {
      this.initCategory(newValue)
    },
  },

  mounted() {
    this.initCategory(this.category)
  },

  beforeDestroy() {
    if (this.unwatchCategories != null) {
      this.unwatchCategories()
    }
  },

  methods: {
    initCategory(category) {
      if (category && Object.keys(category).length !== 0) {
        this.selectedCategory = category
        document.getElementById('category-display-field').innerText = category.name
      }
    },

    openCategoriesModal() {
      if (this.flatCategories !== null && this.flatCategories.length !== 0) {
        this.setDisplayedCategories(null, this.flatCategories)
      } else {
        this.unwatchCategories = this.$store.watch(
          (state, getters) => getters.flatCategories, categories => {
            this.setDisplayedCategories(null, categories)
          },
        )
      }

      this.breadCrumb = [{
        name: 'الكل',
        id: 0,
        parent_id: null,
      }]

      this.$bvModal.show('select-categories')
    },

    hasChildren(category) {
      const children = this.flatCategories.filter(cat => cat.parent_id === category.id)

      return children.length !== 0
    },

    setDisplayedCategories(parentCategory, categories) {
      if (categories != null) {
        this.displayedCategories = categories.filter(cat => cat.parent_id === null)

        return
      }

      this.displayedCategories = this.flatCategories.filter(cat => cat.parent_id === parentCategory.id)

      if (this.displayedCategories.length === 0) {
        this.$emit('categorySelected', parentCategory)
        this.selectedCategory = parentCategory
        document.getElementById('category-display-field').innerText = parentCategory.name
        this.hideModal()
      }

      this.breadCrumb.push(parentCategory)
    },

    setFromBreadCrumb(category) {
      this.displayedCategories = this.flatCategories.filter(cat => cat.parent_id === category.parent_id)

      const index = this.breadCrumb.findIndex(cat => cat.id === category.id)

      this.breadCrumb.splice(index + 1, this.breadCrumb.length - index)
    },

    hideModal() {
      this.$bvModal.hide('select-categories')
    },

    setCategory() {
      this.hideModal()
    },
  },
}
</script>

<template>
  <div>
    <span
      id="category-display-field"
      type="text"
      class="form-control"
      @click="openCategoriesModal()"
    />

    <b-modal
      id="select-categories"
      centered
      :hide-footer="true"
      title="إختر الفئة"
    >
      <div class="bg-white p-3 rounded box-shadow categories-menu">
        <div class="w-100 d-inline-block pb-1 categories-breadCrumb">
          <div
            v-for="(item, index) in breadCrumb"
            :key="index"
            class="d-inline-block"
          >
            <a
              href="javascript:void(0)"
              class="font-weight-think"
              @click.prevent="setFromBreadCrumb(item)"
            >
              {{ item.name }}
            </a>
            <feather-icon
              v-if="index < breadCrumb.length - 1"
              icon="ChevronLeftIcon"
            />
          </div>
        </div>
        <ul class="list-unstyled ">
          <li
            v-for="(cat, index) in displayedCategories"
            :key="index"
          >
            <a
              href=""
              class="font-weight-light"
              @click.prevent="setDisplayedCategories(cat)"
            >
              {{ cat.name }}
              <feather-icon
                v-if="hasChildren(cat)"
                icon="ChevronLeftIcon"
              />
            </a>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
  .categories-menu {
    .categories-breadCrumb {
      font-weight: 500 !important;
    }

    width: 100% !important;
    display: inline-block;

    a {
      color: #45505e;
      font-size: 1.2rem;
    }

    a:hover {
      color: #ff5b69;
    }

    .parnt-category {
      padding: 5px 0px 0px 10px;
      display: inline-block;
      width: 100% !important;
    }

    .sub-categories-menu li {
      padding: 0px 5px;
      width: 100%;
      display: inline-block;

      label {
        font-size: 1.1rem !important;
      }
    }
  }
</style>
