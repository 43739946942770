import axios from '@/libs/axios'

export default {
  get(slug) {
    return axios.get(`items/${encodeURI(slug)}`)
  },

  getUserItems(uid) {
    return axios.get(`users/${uid}/items`)
  },

  search(formData) {
    return axios.get('search', { params: formData })
  },

  create(formData) {
    return axios.post('item', formData)
  },

  edit(slug, formData) {
    return axios.post(`item/${slug}/edit`, formData)
  },

  delete(slug) {
    return axios.delete(`item/${slug}`)
  },
}
