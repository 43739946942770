<template>
  <VueUploadMultipleImage
    drag-text=""
    :show-add="isMultiple"
    :multiple="isMultiple"
    browse-text="إختر الصور"
    :data-images="dataImages"
    :show-primary="false"
    :max-images="maxImages"
    :show-edit="false"
    @before-remove="beforeRemove"
    @upload-success="uploadImageSuccess"
  />
</template>

<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image'

export default {
  components: {
    VueUploadMultipleImage,
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['maxImages', 'multiple', 'dataImages'],
  data() {
    return {
      images: [],
    }
  },

  computed: {
    isMultiple() {
      return this.multiple != null ? this.multiple : true
    },
  },

  methods: {
    beforeRemove(index, done) {
      this.images.splice(index, 1)
      done()

      this.$emit('imagesUpdated', this.images)
    },

    uploadImageSuccess(formData) {
      // eslint-disable-next-line no-restricted-syntax
      for (const img of formData.values()) {
        this.images.push(img)
      }

      this.$emit('imagesUpdated', this.images)
    },
  },
}
</script>
